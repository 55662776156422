<template>
  <div>
    <select id="Land" :name="fieldName" class="form-control" v-model="location" @change="onChange($event)">
      <option disabled selected>{{ translationCountry }} *</option>
      <option v-for="country in countries" :value="country.VALUE">
        {{ country.KEY }}
      </option>
    </select>
    <div class="business" v-if="showUst">
      <input class="form-control" :placeholder="translationUstid" id="UstID" type="text"
             name="UstID" :value="ustId"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "countrySelect",
  data: function () {
    return {
      fieldName: 'Land',
      location: '',
      showUst: false
    }
  },
  props: ['countries', 'translationCountry', 'translationUstid', 'ustId', 'fieldName', 'useService'],
  created: function () {
    if (!this.$attrs.fieldName || this.$attrs.fieldName === '') {
      //this.fieldName = 'Land'
    }

    if (!this.$attrs.location || this.$attrs.location === '') {
      let ident = this.countries[0].IDENT
      if (ident ==='') {
        ident='de';
      }
      switch (ident) {
        case 'de':
          this.location = 'D'
          break
        case 'en':
          this.location = 'D'
          //this.location = 'GB' // old
          break
        case 'fr':
          this.location = 'D'
          //this.location = 'F' // old
          break
      }
    } else {
      this.location = this.$attrs.location
    }
    if (this.$attrs.useService) {
      this.fetchCountryData();
    }
  },
  methods: {
    fetchCountryData() {
      const that = this;
      const host = window.location.protocol+"//"+window.location.hostname;
      let countryCode = this.location;
      fetch(host+"/api/v1/shipment/country-info/?countryCode="+countryCode).then(async (response) => {
        that.countryDetails = (await response.json())['data'];
        that.$store.commit('setOrderFormCountryDetails', that.countryDetails);
      });
    },
    onChange(event) {
      this.location = event.currentTarget.value;
      if (this.$attrs.useService)
        this.fetchCountryData();
    }
  },
  watch: {
    location: function(country) {
      let newCountry = this.countries.find (obj => {
        return obj.VALUE === country
      })
      if (newCountry) {
        this.showUst = (newCountry.ISEU === '1' && newCountry.VALUE !== 'D')
      }
      this.fetchCountryData();
    }
}
}
</script>