<template>
  <div><!--PICKUP-->
    <div v-if="isPickupAvailable">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "formOrderPickup",
  props: [],
  methods: {

  },
  computed: {
    isPickupAvailable() {
      return (this.$store.state.orderForm.isPickupAvailable);
    }
  }
}
</script>