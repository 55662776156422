<template>
  <div class="pds-gallery">
    <div class="pds-gallery-active" v-if="this.imageURLS.length > 1">
      <a class="pds-gallery-navigation _prev" @click="prevImage()">
        <span class="icon icon-chevron-left"></span>
      </a>
      <a class="openProductGallery">
        <img :src="'/images/thumbnails/360x270/' + active" :alt="tooltip" :title="tooltip" />
      </a>
      <a class="pds-gallery-navigation _next" @click="nextImage()">
        <span class="icon icon-chevron-right"></span>
      </a>
      <div class="pds-gallery-image-description" v-html="description" :title="description">{{ description }}</div>
    </div>
    <div class="pds-gallery-thumbnails" v-if="this.imageURLS.length > 1">
      <span v-for="image in images" v-if="(image.SRC !== null)">
        <a @click.prevent.stop="active = image.SRC; description = image.DESCRIPTION_FK" :class="{active: image.SRC === active}"
           data-lightbox="productImage" :href="'/images/thumbnails/800x600/' + image.SRC">
          <img :src="'/images/thumbnails/360x270/' + image.SRC" :alt="image.TOOLTIP_TXT" :title="image.TOOLTIP_TXT"/>
        </a>
        <a v-if="image.SRC_OPTIONAL !== null"
            @click.prevent.stop="active = image.SRC_OPTIONAL"
            data-lightbox="productImage" :href="'/images/thumbnails/800x600/' + image.SRC_OPTIONAL"
            :class="{active: image.SRC_OPTIONAL === active}">
          <img :src="'/images/thumbnails/360x270/' + image.SRC_OPTIONAL" :alt="image.TOOLTIP_TXT" :title="image.TOOLTIP_TXT"/>
        </a>
      </span>
    </div>
    <div class="pds-gallery-active" v-else>
      <a :href="'/images/thumbnails/800x600/' + active" data-lightbox="productSingleImage" class="openProductGallery">
        <img :src="'/images/thumbnails/360x270/' + active" :alt="tooltip" :title="tooltip" />
      </a>
      <div class="pds-gallery-image-description" v-html="description" :title="description">{{ description }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "productImages",
  data() {
    return {
      active: null,
      tooltip: "",
      imageURLS: Array,
      imageDESC: Array,
      description: ""
    }
  },
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  methods: {
    collectImageUrls() {
      let imageDesc = []
      let imageCollection = []
      for (let image in this.images) {
        if (this.images[image].SRC !== null) {
          imageCollection.push(this.images[image].SRC)
        }

        if (this.images[image].SRC_OPTIONAL !== null) {
          imageCollection.push(this.images[image].SRC_OPTIONAL)
        }

        if (this.images[image].DESCRIPTION_FK !== "" && this.images[image].DESCRIPTION_FK !== "undefined" && this.images[image].DESCRIPTION_FK) {
          imageDesc.push(this.images[image].DESCRIPTION_FK)
          if (this.images[image].SRC_OPTIONAL !== null) {
            imageDesc.push(this.images[image].DESCRIPTION_FK)
          }
        } else {
          imageDesc.push("");
        }

      }
      return {'urls': imageCollection, 'desc': imageDesc}
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    prevImage() {
      let current = this.getKeyByValue(this.imageURLS, this.active)
      current--
      let prev = this.imageURLS[current]
      if (prev === undefined) {
        current = this.imageURLS.length - 1
        prev = this.imageURLS[current]
      }
      this.active = prev
      this.description = this.imageDESC[current]
    },
    nextImage() {
      let current = this.getKeyByValue(this.imageURLS, this.active)
      current++
      let next = this.imageURLS[current]
      if (next === undefined) {
        next = this.imageURLS[0]
        current = 0;
      }
      this.active = next
      this.description = this.imageDESC[current]
    }
  },
  mounted() {
    this.active = this.images[0].SRC
    this.tooltip = this.images[0].TOOLTIP_TXT
    this.description = this.images[0].DESCRIPTION_FK
    let d = this.collectImageUrls();
    this.imageURLS = d['urls'];
    this.imageDESC = d['desc'];
  }
}
</script>