<template>
  <div class="infoBox">
    {{ translations['AF_PAYABLE_SERVICE'] }}:
    <div v-if="getShipmentGlobalCharge!=='0,00'">
    {{ translations['AF_ABBR_PLUS'] }} <span class="hl">{{ getShipmentGlobalCharge }} EUR</span>
    {{ translations['AF_EURO_INCLUDING_VAT'] }}
    </div>
    <div v-else class="hl">
      {{ translations['AF_PAYABLE_ADDON'] }}
    </div>
    <input type="hidden" name="shipmentGlobalCharge" id="shipmentglobalcharge"
           :value="getShipmentGlobalCharge"/>
  </div>
</template>

<script>

export default {
  name: "formOrderPickupPriceInfo",
  props: ['translations'],
  methods: {
    fetchCountryData() {
      const that = this;
      const host = window.location.protocol+"//"+window.location.hostname;

      let countryCode = this.$store.state.orderForm.countryCode;

      fetch(host+"/api/v1/shipment/country-info/?countryCode="+countryCode).then(async (response) => {
        that.countryDetails = (await response.json())['data'];
        that.$store.commit('setOrderFormCountryDetails', that.countryDetails);
      });
    }
  },
  computed: {
    getShipmentGlobalCharge() {
      let x = this.$store.state.orderForm.countryDetails?.SHIPMENTGLOBALCHARGE;
      return x;
    }
  }

}
</script>
<style>
.hl {
  font-weight: bold;
  color: #000;
}
</style>