
$(document).ready(function () {

    // Offcanvas mobile menu
    $("[data-trigger='#navbar_main']").on("click", function (e) {
        e.preventDefault()
        e.stopPropagation()
        var offcanvas_id = $(this).attr('data-trigger')
        $(offcanvas_id).toggleClass("show")
        $('body').toggleClass("offcanvas-active")
        $(".screen-overlay").toggleClass("show")
        $(".offcanvas-header, .screen-overlay").click(function (e) {
            $(".screen-overlay").removeClass("show")
            $(".mobile-offcanvas").removeClass("show")
            $("body").removeClass("offcanvas-active")
        })
    });

    /*
    $('.nav-item').hover(function() {

        $(this).find('.nav-dropdown').stop(true, true).delay(2000).fadeIn(500);
    }, function() {
        $(this).find('.nav-dropdown').stop(true, true).delay(2000).fadeOut(500);
    });

     */

    // Order form chooser (business/private)
    $('#formChooser input').change(function () {
        let type = $(this).attr('value')
        if (type === 'business') {
            $('#xf01').addClass('form_business').removeClass('form_private')
        } else if (type === 'private') {
            $('#xf01').addClass('form_private').removeClass('form_business')
        }
    })

    // Footer link's scroll to top
    $('#brands a').click(function () {
        $('html').animate({scrollTop: 0}, 500, 'swing')
    })

    // Show more parts
    let partsContainer = $('.partListing')
    let partList = $('.partList')
    if (partsContainer.length && parseInt(partList.css('height')) > 136) {
        let height = parseInt(partList.css('height'))
        let showMoreLink = $('.partListing .showMore')
        let showLessLink = $('.partListing .showLess')
        partList.css('height', 60)
        $('.showMoreParts._bottom').css('display', 'block')
        showMoreLink.click(function() {
            partList.animate( { height: height }, 1000, 'swing' )
            showMoreLink.hide()
            showLessLink.css('display', 'block')
        })
        showLessLink.click(function() {
            partList.animate( { height: 60 }, 1000, 'swing' )
            showLessLink.hide()
            showMoreLink.css('display', 'block')
        })
    }

    // Lightbox functionalities on pds
    lightbox.option({
        'albumLabel': '%1 / %2',
        'maxHeight': 600,
        'maxWidth': 800,
        'wrapAround': true
    })
    if ($('.pds-gallery-thumbnails a').length > 1) {
        $('.openProductGallery').click(function() {
            $('.pds-gallery-thumbnails .active').click()
        })
    }

    // Alert popup
    let el_AlertPopup =$('#alertPopup .modal-content');
    let popupContent = el_AlertPopup.html();
    if (popupContent !== undefined) {
        popupContent = popupContent.trim();
        if ((popupContent !== '') && (localStorage.tachopixPopup !== popupContent)) {
            $('#alertPopup').modal()
            localStorage.tachopixPopup = popupContent;
        }
    }


    class TachoNavigation {

        constructor() {
            this.delayed = {};
            this.open = null;
            this.showCurrentCategory = null;
            this.bindEvents();
        }



        bindEvents() {
            const that = this;
            Array.from(document.querySelectorAll('.navbar ul.navbar-nav li')).forEach((el)=> {
                el.addEventListener('mouseover', (e)=> {
                    let elem = e.currentTarget;
                    if ('category' in elem.dataset) {
                        that.navOpen(elem.dataset.category)
                    }
                });
                el.addEventListener('mouseleave', (e)=> {
                    let elem = e.currentTarget;
                    if ('category' in elem.dataset) {
                        that.navClose(elem.dataset.category)
                    }
                });
            });

            Array.from(document.querySelectorAll('select.brandsList')).forEach((el)=> {
                el.addEventListener('change', (e) => {
                    window.location.href=e.currentTarget.value;
                });
            });


        }

        showBackdrop(bool) {
            if (bool) {
                document.querySelector('#backdrop').classList.add('active');
            } else {
                document.querySelector('#backdrop').classList.remove('active');
            }

        }

        showCategory(category) {
            const that = this;
            this.showCurrentCategory = category;
            Array.from(document.querySelectorAll('.navbar:not(.mobile-offcanvas) ul.navbar-nav li')).forEach((el) => {
                if ('category' in el.dataset) {
                    if (parseInt(el.dataset.category) === parseInt(category)) {
                        el.querySelector('.nav-dropdown').classList.add('show');
                    } else {
                        el.querySelector('.nav-dropdown').classList.remove('show');
                    }
                }
            });
            Array.from(document.querySelectorAll('.navbar.mobile-offcanvas ul.navbar-nav li')).forEach((el) => {
                if ('category' in el.dataset) {
                    if (parseInt(el.dataset.category) === parseInt(category)) {
                        el.classList.add('open');
                        el.classList.add('active');
                        el.querySelector('.nav-dropdown').classList.add('show');
                    } else {
                        el.classList.remove('open');
                        el.classList.remove('active');
                        el.querySelector('.nav-dropdown').classList.remove('show');
                    }
                }
            });
        }

        openCategory() {
            (parseInt(this.open) !== parseInt(category)) ? this.open = category : this.open = ''
        }

        navOpen(category) {
            let that = this;

            if (Object.keys(this.delayed).length || this.showCurrentCategory !== null) {
                that.showCategory(category);
            } else {
                if (!that.delayed.hasOwnProperty(category)) {
                    that.delayed[category] = {
                        timeout: setTimeout(()=> {
                            that.showCategory(category);
                            that.showBackdrop(true);
                        }, 400)
                    }
                }
            }
        }

        navClose(category) {
            let that = this;
            if (this.delayed.hasOwnProperty(category)) {
                clearTimeout(this.delayed[category].timeout);
                delete this.delayed[category];
            }
            setTimeout(() => {
                if (Object.keys(that.delayed).length === 0) {
                    if (that.showCurrentCategory === category) {
                        that.showCategory(null);
                        that.showBackdrop(false);
                    } else if (that.showCurrentCategory === null) {
                        that.showCategory(null);
                        that.showBackdrop(false);
                    }
                }
            },50);
        }
    }

    let nav = new TachoNavigation();


    Array.from(document.querySelectorAll('.set-brand')).forEach((el)=>{
        el.addEventListener('click', (e)=> {
            if ("brand" in e.currentTarget.dataset) {
                let brandName = el.dataset.brand;
                if (document.querySelector('.chooserCategory')) {
                    window.vm.$store.commit('setSelectedBrand', brandName);
                } else {
                    // otherwise set brand to localstorage and goto startpage
                    window.localStorage.setItem('footer-selected-brand', brandName);
                    window.location.href=window.location.protocol+'//'+window.location.host;
                }
            }
        })
    });

    function adjustMobileFooterCollapse() {
        if ((screen.width <= 1180)) {
            Array.from(document.querySelectorAll('.mobile-collapse-toggle')).forEach((el)=> {
                el.dataset.toggle = 'collapse';
            });

            Array.from(document.querySelectorAll('.mobile-collapse')).forEach((el)=> {
                el.classList.add('collapse');
            });
        }
    }

    window.addEventListener('resize', () => {
        adjustMobileFooterCollapse();
    });
    adjustMobileFooterCollapse();


})