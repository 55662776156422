<template>
  <div class="productCarousel">

    <mq-layout :mq="['small', 'medium', 'large']">
      <ul class="productCarouselList">
        <li class="productCarouselCategory"
            v-for="(category, key, index) in items"
            v-bind:key="key"
            v-bind:id="category.id"
            :class="{ 'active': category.id === activeCategory }">
          <a class="productCarouselNavigation"
             v-on:click="activeCategory = category.id">
            {{ category.title }}
          </a>
          <div class="tab-content"
               :class="{ 'active': category.id === activeCategory }">
            <tiny-slider :mouse-drag="true" :loop="false" :nav="false"
                         :controlsText="[
                       '<span class=\'icon icon-chevron-left\'></span>',
                       '<span class=\'icon icon-chevron-right\'></span>'
                       ]"
                         gutter="20" :responsive="{ 768: { items: 2}, 1180: { items: 4}}">
              <div v-for="(product) in category.content" class="productCarouselDetail">
                <div>
                  <div class="productCarouselImage">
                    <a :href="getProductLink(product.CLASS, product.NAVIGATION_FK, product.VEHICLEBRAND_FK, product.PAGETYPE, product.PK, product)">
                    <img :src="$attrs.thumbpath +'360x270/'+ product.IAVIMAGE" type="image/jpg" :alt="product.INSTRUMENTACTION" />
                      <!--<img src="/tpl/_img/content/stock.svg" alt="No image provided">
                    </object>-->
                    </a>
                  </div>
                  <div class="productCarouselName">{{ product.INSTRUMENTACTION }}</div>
                  <product-link :product="product" :btnText="$attrs.buttontxt['SHOW_DETAILS']"></product-link>
                </div>
              </div>
            </tiny-slider>
          </div>
        </li>
      </ul>
    </mq-layout>


    <mq-layout mq="huge">
      <ul class="productCarouselList">
        <li class="productCarouselCategory"
            v-for="(category, key, index) in items"
            v-bind:key="key"
            v-bind:id="category.id"
            :class="{ 'active': category.id === activeCategory }">
          <a class="productCarouselNavigation"
             v-on:click="activeCategory = category.id">
            {{ category.title }}
          </a>
        </li>
      </ul>
      <div class="tab-content"
           v-for="(category, key, index) in items"
           :class="{ 'active': category.id === activeCategory }">
        <tiny-slider :mouse-drag="true" :loop="false" :nav="false"
                     :controlsText="[
                       '<span class=\'icon icon-chevron-left\'></span>',
                       '<span class=\'icon icon-chevron-right\'></span>'
                       ]"
                     gutter="20" :responsive="{ 768: { items: 2}, 1180: { items: 4}}">
          <div v-for="(product) in category.content" class="productCarouselDetail">
            <div>
              <div class="productCarouselImage">
                <a :href="getProductLink(product.CLASS, product.NAVIGATION_FK, product.VEHICLEBRAND_FK,
                  product.PAGETYPE, product.PK, product)">
                  <img :src="$attrs.thumbpath +'360x270/'+ product.IAVIMAGE" type="image/jpg" :alt="product.INSTRUMENTACTION"
                          :title="product.INSTRUMENTACTION" />
                    <!--<img src="/tpl/_img/content/stock.svg">-->
                  <!--</img>-->
                </a>
              </div>
              <div class="productCarouselName">
                <a :href="getProductLink(product.CLASS, product.NAVIGATION_FK, product.VEHICLEBRAND_FK,
                  product.PAGETYPE, product.PK, product)">{{ product.INSTRUMENTACTION }}</a>
              </div>
              <product-link :product="product" :btnText="$attrs.buttontxt['SHOW_DETAILS']"></product-link>
            </div>
          </div>
        </tiny-slider>
      </div>
    </mq-layout>
  </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider'

export default {
  name: "relatedProducts",
  data() {
    return {
      activeCategory: '',
      items: null
    }
  },
  components: {
    'tiny-slider': VueTinySlider
  },
  props: {
    products: {
      type: Array,
      required: true
    },
  },
  created() {
    this.activeCategory = this.$attrs.category;
    this.items = this.getGroupNames(this.products, 'CLASS', ['shop','multimedia'], {'speedodesign': 'repair'}, {
      'repair': 1,
      'navirepair': 2,
      'controlunit': 3,
      'speedodesign': 4
    })
    /**
     * @see https://support.digitalmanufaktur.com/browse/TACHO-87
     * hide category "shop" on related Products view
     */
  },
  methods: {
    getGroupNames: function groupBy(products, groupByKey, ignoreGroupsBy_groupByKey = [], preferredGroupKeys = {}, groupOrder = {}) {

      let preferredGroupKey  = null;
      if (preferredGroupKeys.hasOwnProperty(this.activeCategory)) {
          preferredGroupKey = preferredGroupKeys[this.activeCategory];
      }
      let productGroups = {};
      let sortedProductGroups = [];

      // group products by key
      products.forEach(product => {
        // skip if ignored group
        if (!ignoreGroupsBy_groupByKey.includes(product[groupByKey])) {
          // build default group item
          if (!(product[groupByKey] in productGroups)) {
            let title = product['INSTRUMENTACTIONTYPE'];
            productGroups[product[groupByKey]] = {
              'title': title,
              'content': [],
              'id': product[groupByKey]
            }
          }
          // add product to group
          if (product[groupByKey] in productGroups) {
            if ('content' in  productGroups[product[groupByKey]]) {
              productGroups[product[groupByKey]]['content'].push(product);
            }
          }
        }
      });

      let that = this;
      let hasEmptyActiveCategory = true;
      let alternativeActiveCategory = null;
      let preferredAlternativeGroup = null;

      for (let groupIdent in productGroups) {
        let group = productGroups[groupIdent];

        if (that.activeCategory === groupIdent) {
          hasEmptyActiveCategory = false;
        }
        if (preferredGroupKey !== null && groupIdent === preferredGroupKey) {
          preferredAlternativeGroup = groupIdent;
        }

        let isEmpty =  (!group['content'].length);
        if (isEmpty && (that.activeCategory === groupIdent)) {
            hasEmptyActiveCategory = true;
        } else if (!isEmpty && !alternativeActiveCategory) {
          alternativeActiveCategory = groupIdent;
        }

        if (isEmpty) {
          delete productGroups[groupIdent];
        } else {
          // sort products inside group
          let products = productGroups[groupIdent]['content'];
          products.sort(
              function compare(a, b) {
                if (a.INSTRUMENTACTION < b.INSTRUMENTACTION) {
                  return -1;
                }
                if (a.INSTRUMENTACTION > b.INSTRUMENTACTION) {
                  return 1;
                }
                return 0;
              }
          );


         if (groupIdent in groupOrder) {
           sortedProductGroups[groupOrder[groupIdent]] = group;
         }

        }
      }
      if (hasEmptyActiveCategory && alternativeActiveCategory !== null ) {
        if (preferredAlternativeGroup !== null) {
          alternativeActiveCategory = preferredAlternativeGroup;
        }
        this.activeCategory = alternativeActiveCategory;
      }
      return sortedProductGroups.filter(function () { return true });
    }
  }
}
</script>