<template>
    <div>
        <a :href="getProductLink(this.product.CLASS, this.product.NAVIGATION_FK, this.product.VEHICLEBRAND_FK,
                this.product.PAGETYPE, this.product.PK, this.product)" class="btn btn-default btn-block">
            {{ btnText }}
        </a>
    </div>
</template>


<script>
    export default {
        name: "productLink",
        data() {
            return {
                product: null
            }
        },
        created() {
            this.product = this.$attrs.product;
            this.btnText = this.$attrs.btnText;
        }
    }
</script>