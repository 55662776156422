var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productCarousel"},[_c('mq-layout',{attrs:{"mq":['small', 'medium', 'large']}},[_c('ul',{staticClass:"productCarouselList"},_vm._l((_vm.items),function(category,key,index){return _c('li',{key:key,staticClass:"productCarouselCategory",class:{ 'active': category.id === _vm.activeCategory },attrs:{"id":category.id}},[_c('a',{staticClass:"productCarouselNavigation",on:{"click":function($event){_vm.activeCategory = category.id}}},[_vm._v("\n          "+_vm._s(category.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"tab-content",class:{ 'active': category.id === _vm.activeCategory }},[_c('tiny-slider',{attrs:{"mouse-drag":true,"loop":false,"nav":false,"controlsText":[
                     '<span class=\'icon icon-chevron-left\'></span>',
                     '<span class=\'icon icon-chevron-right\'></span>'
                     ],"gutter":"20","responsive":{ 768: { items: 2}, 1180: { items: 4}}}},_vm._l((category.content),function(product){return _c('div',{staticClass:"productCarouselDetail"},[_c('div',[_c('div',{staticClass:"productCarouselImage"},[_c('a',{attrs:{"href":_vm.getProductLink(product.CLASS, product.NAVIGATION_FK, product.VEHICLEBRAND_FK, product.PAGETYPE, product.PK, product)}},[_c('img',{attrs:{"src":_vm.$attrs.thumbpath +'360x270/'+ product.IAVIMAGE,"type":"image/jpg","alt":product.INSTRUMENTACTION}})])]),_vm._v(" "),_c('div',{staticClass:"productCarouselName"},[_vm._v(_vm._s(product.INSTRUMENTACTION))]),_vm._v(" "),_c('product-link',{attrs:{"product":product,"btnText":_vm.$attrs.buttontxt['SHOW_DETAILS']}})],1)])}),0)],1)])}),0)]),_vm._v(" "),_c('mq-layout',{attrs:{"mq":"huge"}},[_c('ul',{staticClass:"productCarouselList"},_vm._l((_vm.items),function(category,key,index){return _c('li',{key:key,staticClass:"productCarouselCategory",class:{ 'active': category.id === _vm.activeCategory },attrs:{"id":category.id}},[_c('a',{staticClass:"productCarouselNavigation",on:{"click":function($event){_vm.activeCategory = category.id}}},[_vm._v("\n          "+_vm._s(category.title)+"\n        ")])])}),0),_vm._v(" "),_vm._l((_vm.items),function(category,key,index){return _c('div',{staticClass:"tab-content",class:{ 'active': category.id === _vm.activeCategory }},[_c('tiny-slider',{attrs:{"mouse-drag":true,"loop":false,"nav":false,"controlsText":[
                     '<span class=\'icon icon-chevron-left\'></span>',
                     '<span class=\'icon icon-chevron-right\'></span>'
                     ],"gutter":"20","responsive":{ 768: { items: 2}, 1180: { items: 4}}}},_vm._l((category.content),function(product){return _c('div',{staticClass:"productCarouselDetail"},[_c('div',[_c('div',{staticClass:"productCarouselImage"},[_c('a',{attrs:{"href":_vm.getProductLink(product.CLASS, product.NAVIGATION_FK, product.VEHICLEBRAND_FK,
                product.PAGETYPE, product.PK, product)}},[_c('img',{attrs:{"src":_vm.$attrs.thumbpath +'360x270/'+ product.IAVIMAGE,"type":"image/jpg","alt":product.INSTRUMENTACTION,"title":product.INSTRUMENTACTION}})])]),_vm._v(" "),_c('div',{staticClass:"productCarouselName"},[_c('a',{attrs:{"href":_vm.getProductLink(product.CLASS, product.NAVIGATION_FK, product.VEHICLEBRAND_FK,
                product.PAGETYPE, product.PK, product)}},[_vm._v(_vm._s(product.INSTRUMENTACTION))])]),_vm._v(" "),_c('product-link',{attrs:{"product":product,"btnText":_vm.$attrs.buttontxt['SHOW_DETAILS']}})],1)])}),0)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }