import Vue from 'vue'
import VueMq from 'vue-mq'
import Vuex from 'vuex'

import RelatedProducts from './components/relatedProducts'
import ProductLink from './components/productLink'
//import FooterCollapses from './components/footerCollapses'
//import MegaMenu from './components/megaMenu'
import Carousel from './components/carousel'
//import ProductListing from './components/productListing'
import ProductImages from './components/productImages'
//import Breadcrumbs from './components/breadcrumbs'
import CountrySelect from './components/countrySelect'


import ChooserWidget from './components/chooserWidget'
import IndexPageChooser from './components/choosers/indexPageChooser';
import ContactFormChooser from './components/choosers/contactFormChooser'
import OrderFormChooser from "./components/choosers/orderFormChooser";

import FormOrderSpeedorent from './components/form/formOrderSpeedorent'
import FormOrderPickup from './components/form/formOrderPickup'
import FormOrderPickupPriceInfo from "./components/form/formOrderPickupPriceInfo";


Vue.use(VueMq, {
    breakpoints: {
        small: 576,
        medium: 768,
        large: 1180,
        huge: Infinity
    },
    defaultBreakpoint: 'sm'
})

Vue.mixin({
    methods: {
        getProductLink(
            class_fk,
            navigation_fk = false,
            vehiclebrand_fk = false,
            pagetype_fk = 'page',
            product_fk = false,
            product = false
            //, vmodel_fk = false
        ) {

            function slug(type, ids) {
                let entries = window.slugs.filter(i => (ids.includes(i.PK) && i.TYPE==type));
                if (entries.length) {
                    return "/"+entries[0].SLUG;
                }
            }

            let link = ''

            if (document.documentElement.lang !== 'de') {
                link+="/"+document.documentElement.lang+""
            }

            if (product) {
                link+="/"+product.SLUG+".html";
            } else {
                //if (class_fk !== 'index')   { link += '/' + class_fk }
                if (navigation_fk) {
                    link+=slug(1, [parseInt(navigation_fk)]);
                }
                if (pagetype_fk === 'iav') {
                    if (vehiclebrand_fk)        {
                        link+=slug(4, [parseInt(vehiclebrand_fk)]);
                    }
                } else if (pagetype_fk === 'page') {
                    if (vehiclebrand_fk)        {
                        link+=slug(2, [parseInt(vehiclebrand_fk)]);
                    }
                }

                if (!product_fk) {
                    link+="/";
                }
            }







            /*
                       const response = await fetch("/route.php?class="+class_fk+"&nav="+navigation_fk+"&subnav="+vehiclebrand_fk+"&colnav="+product_fk+"&pagetype="+pagetype_fk);
                       let link = at response.text();
            /*
                       let link = ''
                       if (class_fk !== 'index')   { link += '/' + class_fk }
                       if (navigation_fk)          { link += '/nav.' + navigation_fk }
                       if (vehiclebrand_fk)        { link += '/subnav.' + vehiclebrand_fk }
                       if (pagetype_fk)            { link += '/pagetype.' + pagetype_fk }
                       if (product_fk)             { link += '/colnav.' + product_fk }
          */
    //        if (vmodel_fk)             { link += '/vmid.' + vmodel_fk }
            return link;
        }
    }
})

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        selectedBrand: '',
        selectedModel: '',
        selectedModelName: '',
        orderForm: {
            pieceDetails: null,
            isPickupAvailable: true,
            countryDetails: null
        }
    },
    mutations: {
        setSelectedBrand (state, brandName) {
            state.selectedBrand = brandName
        },
        setSelectedModel (state, modelId) {
            state.selectedModel = modelId
        },
        setSelectedModelName (state, modelName) {
            state.selectedModelName = modelName;
        },
        resetSelectedModel (state) {
            state.selectedModel = ''
        },
        setOrderFormPieceDetails (state, pieceDetails) {
            state.orderForm.pieceDetails = pieceDetails;
        },
        setOrderFormPickupAvailable (state, isAvailable) {
            state.orderForm.isPickupAvailable = isAvailable;
        },
        setOrderFormCountryDetails (state, countryDetails) {
            state.orderForm.countryDetails = countryDetails;
        }
    },
    getters: {
        getSelectedModel: state => {
            return state.selectedModel
        },
        getSelectedModelName: state => {
            return state.selectedModelName
        },
        getOrderFormPieceDetails: state => {
            return state.orderForm.pieceDetails;
        },
        getOrderFormCountryDetails: state => {
            return state.orderForm.countryDetails;
        }
    }

})

Vue.component('relatedProducts', RelatedProducts)
Vue.component('productLink', ProductLink)
//Vue.component('footerCollapses', FooterCollapses)
//Vue.component('megaMenu', MegaMenu)
Vue.component('carousel', Carousel)
//Vue.component('productListing', ProductListing)
Vue.component('productImages', ProductImages)
//Vue.component('breadcrumbs', Breadcrumbs)
Vue.component('countrySelect', CountrySelect)

Vue.component('chooserWidget', ChooserWidget)
Vue.component('indexPageChooser', IndexPageChooser)
Vue.component('contactFormChooser', ContactFormChooser)
Vue.component('orderFormChooser', OrderFormChooser)
Vue.component('formOrderSpeedorent', FormOrderSpeedorent)
Vue.component('formOrderPickup', FormOrderPickup)
Vue.component('formOrderPickupPriceInfo', FormOrderPickupPriceInfo)

let vm = new Vue({
    el: '#app',
    store
})
window.vm = vm;

if (window.localStorage.getItem('footer-selected-brand')) {
    let brandName = window.localStorage.getItem('footer-selected-brand');
    vm.$store.commit('setSelectedBrand', brandName);
    window.localStorage.removeItem('footer-selected-brand');
}