<template>
  <div class="cRow">
    <tiny-slider :mouse-drag="true" :nav="false" gutter="20"
                 :controlsText="['<span class=\'icon icon-chevron-left\'></span>','<span class=\'icon icon-chevron-right\'></span>']"
                 :responsive="{768: { items: 2, slideBy: 2}, 1180: { items: 3, slideBy: 3}}" :autoplay="true"
                 :autoplayTimeout="(settings['config'].interval * 1000)" :autoplayHoverPause="true"
    >
      <div v-for="slide in settings['content']" class="slide">
        <div class="cArea">
          <a :href="slide.image_link" :title="sliderProductImages[slide.image].TOOLTIP_TXT">
            <img v-if="slide.useOptionalImage" :src="'/images/thumbnails/360x270/' + sliderProductImages[slide.image].SRC_OPTIONAL" :alt="sliderProductImages[slide.image].TOOLTIP_TXT" :title="sliderProductImages[slide.image].TOOLTIP_TXT"/>
            <img v-else :src="'/images/thumbnails/360x270/' + sliderProductImages[slide.image].SRC" :alt="sliderProductImages[slide.image].TOOLTIP_TXT" :title="sliderProductImages[slide.image].TOOLTIP_TXT"/>
          </a>
          <div class="cTitle">
            <a :href="slide.title_link" class="btn btn-primary" :title="slide.title">
              {{ slide.title }}
            </a>
          </div>
        </div>
      </div>
    </tiny-slider>
  </div>
</template>

<script>
import VueTinySlider from 'vue-tiny-slider'

export default {
  name: "carousel",
  components: {
    'tiny-slider': VueTinySlider
  },
  props: ['settings', 'slider-product-images']
}
</script>