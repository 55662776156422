/**
 * DMF ALGOLIA INTEGRATION
 */

// Algolia Search init
const ALG = window.ALGOLIA;
if (ALG && ALG.active) {

    const algoliasearch = require('algoliasearch');
    const _ = require('lodash');

    /**
     * @click:button mobile search toggle
     */
    document.querySelector('.toggle-search').addEventListener('click', (e) => {
        e.preventDefault();
        let icon = e.currentTarget.querySelector('span.icon');
        if (icon.classList.contains('icon-close')) {
            window.tachoSearch.resetResult();
        }
        icon.classList.toggle('icon-search');
        icon.classList.toggle('icon-close');

       let hsearch = document.querySelector('#header-search-mobile');
       let chooser = document.querySelector('.chooserWidget');
       if (hsearch.classList.contains('d-none')) {
           hsearch.classList.remove('d-none')
           hsearch.querySelector('input[type=search]').focus();
           if (chooser)
            chooser.classList.add('d-none');
       } else {
           hsearch.classList.add('d-none');
           if (chooser)
               chooser.classList.remove('d-none');
       }
    });

    class DmfAlgoliaSearch {

        constructor(config = {})
        {
            this.defaultConfig = {
                search: {
                    maxHitsPerPage: 500,
                    minLength: 2
                },
                selectors: {
                    input: 'input[type=search]',
                    submit: 'button.text-search'
                },
                feature: {
                    qsTags: {
                        enabled: false,
                        className: 'qs-add'
                    },
                    scrollTopOnSearch: {
                        enabled: true,
                        top:0
                    },
                    oneHitRedirect: {
                        enabled: true
                    }
                },
                algolia: {
                    appId: "",
                    publicKey: "",
                    searchIndex: ""
                },
                templatePath: '/tpl/algolia/',
                templateMap: {
                    result: "search.result.tpl"
                }
            };
            this.config = _.merge(this.defaultConfig, config);
            this.init();
        }

        init()
        {
            this.templates = {}; // used as template cache
            this.hostUrl = window.location.protocol+"//"+window.location.hostname;
            this.tplPath = this.config.templatePath;
            this.tplMap = this.config.templateMap;
            this.lang = document.querySelector('html').attributes['lang'].value;
            if (!this.lang || this.lang === '') this.lang='de';
            this.initDomElements();
            this.lastInputElement = null;
            this.bindEvents();
        }

        initDomElements()
        {
            const config = this.getConfig();
            /**
             * base DOM elements
             * @type {{btnSearchSubmit: Element, main: HTMLElement, inputSearch: Element, resultContainer: Element}}
             */
            this.DOM = {
                main: document.querySelector('main'),
                inputSearch: document.querySelectorAll(config.selectors.input),
                btnSearchSubmit: document.querySelectorAll(config.selectors.submit),
                resultContainer: document.querySelector('.search-result-container')
            };

            /**
             * prepare searchResultContainer
             * @type {Element}
             */
            if (this.DOM.resultContainer) {
                this.DOM.resultContainer.innerHTML="";
            } else {
                let resultWrapperEl = document.createElement('div');
                resultWrapperEl.classList.add('search-result-container');
                resultWrapperEl.classList.add('container');
                resultWrapperEl.classList.add('content');
                //this.DOM.main.prepend(resultWrapperEl);
                this.DOM.main.before(resultWrapperEl);
                this.DOM.resultContainer = document.querySelector('.search-result-container');
            }
        }

        getConfig()
        {
            return this.config;
        }

        getLanguagePack()
        {
            let i18n = {
                "de": {
                    "RESULTS_FOUND": "Ergebnisse gefunden",
                    "MORE_DETAILS": "Details ansehen",
                    "NO_RESULTS_FOR": "Keine Ergebnisse gefunden für",
                    "VEHICLE_MODEL": "Fahrzeug-Modell",
                    "FILTER_FREETEXT_MORE_THEN_10_RESULTS": "Für ein optimiertes Suchergebnis ergänzen Sie ein oder mehrere Stichwörter wie zum Beispiel: Marke, Modell, Gerät, Fehler, Teilenummer, etc."
                },
                "en": {
                    "RESULTS_FOUND": "Results found",
                    "MORE_DETAILS": "Show details",
                    "NO_RESULTS_FOR": "No results found for",
                    "VEHICLE_MODEL": "Vehicle-Model",
                    "FILTER_FREETEXT_MORE_THEN_10_RESULTS": "For an optimised search result add one or more keywords such as: brand, model, device, error, part number, etc."
                },
                "fr": {
                    "RESULTS_FOUND": "Résultats trouvés",
                    "MORE_DETAILS": "Regarder les détails",
                    "NO_RESULTS_FOR": "Aucun résultat trouvé pour",
                    "VEHICLE_MODEL": "Modèle de véhicule",
                    "FILTER_FREETEXT_MORE_THEN_10_RESULTS": " Pour un résultat de recherche optimisé, ajoutez un ou plusieurs mots-clés comme par exemple : marque, modèle, appareil, défaut, numéro de pièce, etcPour un résultat de recherche optimisé, ajoutez un ou plusieurs mots-clés tels que : marque, modèle, appareil, erreur, numéro de pièce, etc."
                }
            }
            return i18n[this.lang];
        }

        getSearchString()
        {
            let value = null;
            if (!this.lastInputElement) {
                this.lastInputElement = this.DOM.inputSearch[0];
            }
            value = this.lastInputElement.value;
            this.DOM.inputSearch.forEach((input) => {
                input.value = value;
            });
            return value;
        }

        getSearchIndex(config) {
            const searchClient = algoliasearch(config.algolia.appId, config.algolia.publicKey);
            return searchClient.initIndex(config.algolia.searchIndex);
        }

        search(trackInHistory = false)
        {
            const that = this;
            let config = that.getConfig();
            let qs = this.getSearchString();

            if (trackInHistory) {
                const url = new URL(location);
                url.searchParams.set("qs", qs);
                history.pushState({
                    "algolia": {
                        "query": qs,
                        "scrollY": window.scrollY.toString()
                    }
                }, "", url);
            }

            const index = this.getSearchIndex(config);

            if (qs.length >= config.search.minLength) {
                let filters = 'IDENT:"'+that.lang+'"';
                let response  = index.search(qs, {
                    filters: filters,
                    hitsPerPage: config.search.maxHitsPerPage,
                    page: 0
                },{});
                response.then((result)=> {
                    this.handleResults(result);
                    if (!trackInHistory && history.state.algolia?.scrollY) {
                        setTimeout(()=> {
                            window.scroll({top: parseInt(history.state.algolia.scrollY), left: 0, behavior: 'auto'});

                            let isMobile = (screen.width <= 767);
                            if (isMobile) {
                                document.querySelector('.toggle-search').dispatchEvent(new Event('click'));
                            }

                        }, 100);
                    }
                });
            }
        }

        getPageHitLink(hit)
        {
            if (hit.DIRECT_LINK !== undefined) {
                if (document.documentElement.lang !== "de") {
                    return this.hostUrl+"/"+document.documentElement.lang+"/"+hit.DIRECT_LINK+".html";
                } else {
                    return this.hostUrl+"/"+hit.DIRECT_LINK+".html";
                }
            } else {
                return this.hostUrl+"/"+hit.IAT_NAVIGATION_CLASS+"/nav."+hit.IAT_NAVIGATION_FK+"/subnav."+hit.VEHICLEBRAND_FK+"/pagetype.iav/colnav."+hit.PK;
            }
        }

        resetResult()
        {
            this.DOM.inputSearch.forEach((input) => {
                input.value = "";
            });
            this.DOM.resultContainer.innerHTML = "";
            this.DOM.main.classList.remove('d-none');
        }

        handleResults(result)
        {
            const that = this;
            const l = this.getLanguagePack();
            let config = this.getConfig();

            let resultWrapper = that.DOM.resultContainer;
            resultWrapper.innerHTML = "";

            if (config.feature.oneHitRedirect && result.hits.length === 1) {
                window.location.href = that.getPageHitLink(result.hits[0]); // redirect to product
            } else {
                that.render('result', {
                    result: result,
                    l: l,
                    hostUrl: that.hostUrl,
                    lang: that.lang
                }, (html) => {

                    let x = document.createElement('div');
                    x.innerHTML = html;

                    if (config.feature.scrollTopOnSearch.enabled) {
                        window.scroll({top: config.feature.scrollTopOnSearch.top, left: 0, behavior: 'auto'});
                    }

                    that.DOM.main.classList.add('d-none');
                    resultWrapper.append(x);
                    resultWrapper.querySelectorAll('.result-entry').forEach((element) => {
                        /**
                         * @click:result-item
                         */
                        element.addEventListener('click', (e) => {
                            const url = new URL(location);
                            const params = new Proxy(new URLSearchParams(window.location.search), {
                                get: (searchParams, prop) => searchParams.get(prop),
                            });
                            history.pushState({
                                "algolia": {
                                    "query": params.qs,
                                    "scrollY": window.scrollY.toString()
                                }
                            }, "", new URL(location));
                            window.location.href = element.dataset.link;
                        });
                    });
                    if (config.feature.qsTags) {
                        if (config.feature.qsTags.enabled) {
                            document.querySelectorAll('.'+config.feature.qsTags.className).forEach((element)=> {
                                /**
                                 * @click: qsTags
                                 */
                                element.addEventListener('click', (e)=> {
                                    let val = element.innerHTML;
                                    if (!that.DOM.inputSearch.value.includes(val)) {
                                        that.DOM.inputSearch.value += " "+val;
                                        that.DOM.btnSearchSubmit[0].click();
                                    }
                                });
                            });
                        } else {
                            document.querySelectorAll('.'+config.feature.qsTags.className).forEach((ele)=> {
                                ele.classList.remove(config.feature.qsTags.className);
                            });
                        }
                    }
                });
            }
        }

        bindEvents()
        {
            const that = this;
            const config = that.getConfig();
            setTimeout(()=> {
                if (that.DOM.btnSearchSubmit) {
                    /**
                     * @keydown:enter - global
                     */
                    document.addEventListener('keydown', (e)=> {
                        if (e.key==='Enter') {
                            const activeEl = document.activeElement;
                            that.DOM.inputSearch.forEach((element) => {
                                if (activeEl === element) {
                                    e.preventDefault();
                                    that.search(true);
                                }
                            });
                        }
                    });
                    that.DOM.btnSearchSubmit.forEach((element) => {
                        /**
                         * @click:button search
                         */
                        element.addEventListener('click', function(e) {
                            that.search(true);
                        });
                    });
                }

                if (that.DOM.inputSearch) {
                    that.DOM.inputSearch.forEach((input) => {
                        /**
                         * @paste: into input search
                         */
                        input.addEventListener('paste', (e) => {
                            that.lastInputElement = input;
                            input.dispatchEvent( new KeyboardEvent('keyup', {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            }));
                            setTimeout(()=> {
                                that.search(true);
                            },100);

                        });
                        /**
                         * @keyup: input search
                         */
                        input.addEventListener('keyup', (e) => {
                            that.lastInputElement = input
                            let elem = e.currentTarget;
                            if (elem.value.length) {
                                let parent = elem.parentNode;
                                if (parent.classList.contains('input-search-wrapper')) {
                                    that.renderResetButton(parent, input);
                                }
                            } else if (elem.parentNode.querySelector('span.reset')) {
                                elem.parentNode.querySelector('span.reset').remove();
                                that.resetResult();
                            }
                        });
                    });
                }
                that.handleBackButton();
            },100);

            window.addEventListener('popstate', (e)=> {
                 that.handlePopState(e);
            });
        }

        renderResetButton(parent, input)
        {
            const that = this;
            if (!parent.querySelector('span.reset')) {
                let span = document.createElement('span');
                span.classList.add('reset');
                span.innerHTML = 'x';
                parent.append(span);
                /**
                 * @click:button reset
                 */
                span.addEventListener('click', () => {
                    const url = new URL(location);
                    url.searchParams.delete("qs");
                    history.pushState({}, "", url);
                    input.value = '';
                    input.focus();
                    span.remove();
                    that.resetResult();
                });
            }
        }

        renderResetButtons()
        {
            const that = this;
            this.DOM.inputSearch.forEach((input)=> {
                let parent = input.parentNode;
                that.renderResetButton(parent, input);
            });
        }

        handlePopState(e)
        {
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            if (params.qs === null) {
                this.resetResult();
            } else {
                if (history.state.algolia?.query) {
                    this.DOM.inputSearch[0].value = history.state.algolia.query;
                    this.renderResetButtons();
                    this.search();
                }
            }
        }

        handleBackButton()
        {
            const that = this;
            let config = that.getConfig();
            const isVisitedViaBackButton = performance && performance.getEntriesByType( 'navigation' ).map( nav => nav.type ).includes( 'back_forward' );
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            if (isVisitedViaBackButton && params.qs) {
                setTimeout(()=> {
                    if (history.state.algolia?.query) {
                        that.DOM.inputSearch[0].value = history.state.algolia.query;
                        that.renderResetButtons();
                        that.search();
                    }
                },10);
            } else if (params.qs) {
                that.DOM.inputSearch[0].value = params.qs;
                that.renderResetButtons();
                that.search(true);
            }
        }

        render(tplName, data, fn)
        {
            let tpl = this.tplMap[tplName];
            const that = this;
            if (that.templates[tpl] === undefined) {
                fetch(this.hostUrl+this.tplPath+tpl).then(function (response) {
                    if (response.ok) {
                        return response.text();
                    }
                    throw response;
                }).then(function (text) {
                    that.templates[tpl] = text;
                    let tpl = _.template(text);
                    fn(tpl(data));
                });
            } else {
                let tpl = _.template(that.templates[tpl]);
                fn(tpl(data));
            }
        }
    }

    window.tachoSearch = new DmfAlgoliaSearch({algolia: ALG});
}