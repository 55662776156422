<template>
  <div><!--SPEEDO-->
    <div v-if="isSpeedoRentAvailable">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "formOrderSpeedorent",
  props: [],
  methods: {

  },
  computed: {
    isSpeedoRentAvailable() {
      return (this.$store.state.orderForm.pieceDetails?.ISSPEEDORENT);
    }
  }
}
</script>