<template>
  <div>
    <select class="form-control"
            @change="onChange($event)" id="brand" name="vbrand">
      <option value="" :selected="brandId === null" disabled>
        {{ translations['FILTER_BY_BRAND'] }} *</option>
      <option v-for="(brand) in brands" :value="brand.VALUE"
              :selected="brandId === brand.VALUE">
        {{ brand.KEY }}
      </option>
    </select>
    <select class="form-control" @change="onChange($event)"
            :disabled="isActive['model'] === false" id="model" name="vmodel">
      <option value="" selected disabled>{{ translations['FILTER_BY_MODEL'] }} *</option>
      <option v-for="model in this.filteredModels" :value="model.VALUE"
              :data-parent="parseInt(model.VEHICLEBRAND_FK)" :selected="parseInt(modelId) === parseInt(model.VALUE)">
        {{ model.KEY }}
      </option>
    </select>
    <select class="form-control" @change="onChange($event, '#xf01')"
            :disabled="isActive['piece'] === false" id="piece" name="productId">
      <option value="" selected disabled>{{ translations['FILTER_BY_PIECE'] }} *</option>
      <optgroup v-for="group in categorizedPieces" :label="group.title">
        <option v-for="piece in group.content" :value="piece.PK" :selected="parseInt(pieceId) === parseInt(piece.PK)">
          {{ piece.INSTRUMENTACTION }}
        </option>
      </optgroup>
    </select>

    <div v-if="this.pieceDetails!==null && this.pieceId" class="product-info-box">

      <div v-if="pieceDetails.NETPRICE_TXT!==''" class="infoBox">
        <div v-if="pieceDetails.PRICECTRL==='hidden'">
          {{ translations['AF_PRICE'] }}: <span class="hl">{{ translations['PRICE_HIDDEN'] }}</span>
        </div>
        <div v-else>
          {{ translations['AF_PRICE'] }}: <span v-if="pieceDetails.PRICECTRL==='from'" class="hl">{{ translations['PRICE_FROM'] }}</span>
          <span class="hl">{{ pieceDetails.GROPRICE_TXT }}</span> {{ translations['AF_EURO_INCLUDING_VAT'] }}
        </div>
        <div v-if="pieceDetails.ISSPEEDORENT===1">{{ translations['AF_HIRED_SPEEDOMETER_AVAILABLE'] }}: <span class="hl">{{ translations['YES'] }}</span></div>
        <div v-else>{{ translations['AF_HIRED_SPEEDOMETER_AVAILABLE'] }}: <span class="hl">{{ translations['NO'] }}</span></div>
      </div>

      <input type="hidden" name="xnavclass" id="xnavclass"
             :value="pieceDetails.IAT_NAVIGATION_CLASS" />
      <input type="hidden" name="xpricectrl" id="xpricectrl"
             :value="pieceDetails.PRICECTRL" />
      <input type="hidden" name="xprice" id="xprice"
             :value="pieceDetails.GROPRICE_TXT" />
      <input type="hidden" name="xvehiclebrand" id="xvehiclebrand"
             :value="pieceDetails.VEHICLEBRAND" />
      <input type="hidden" name="xvehiclemodel" id="xvehiclemodel"
             :value="pieceDetails.VEHICLEMODEL" />
      <input type="hidden" name="xinstrumentactiontype"
             id="xinstrumentactiontype"
             :value="pieceDetails.INSTRUMENTACTIONTYPE" />
      <input type="hidden" name="xinstrumentaction"
             id="xinstrumentaction"
             :value="pieceDetails.INSTRUMENTACTION" />
    </div>
  </div>
</template>

<script>
import ChooserWidget from "../chooserWidget"

export default {
  name: "orderFormChooser",
  mixins: [ChooserWidget],
  props: ['current_model', 'current_piece'],
  methods: {
    getBrandByModelId(modelId) {
      return this.models.filter(model => parseInt(model.VALUE) === parseInt(modelId));
    }
  },
  created: function() {
    if (this.current_model !== 0 && this.current_piece !== 0) {
      this.isActive['brand'] = this.isActive['model'] = this.isActive['piece'] = true
      let model = this.getBrandByModelId(this.current_model)
      this.brandId = model[0].VEHICLEBRAND_FK
      this.modelId = this.current_model.toString()
      this.pieceId = this.current_piece.toString()
      this.fetchPieceData();
    }
  }
}
</script>

<style>
.hl {
  font-weight: bold;
  color: #000;
}
</style>