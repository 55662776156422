<template>
  <div>
    <div class="container searchTabs">
      <div class="row d-none d-lg-block">
        <span>{{ translations['SEARCH_TITLE'] }}:</span>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="activeTab === 'brandFilter' ? 'active' : ''"
               @click="activeTab = 'brandFilter'">
              {{ translations['FILTER_BY_BRAND_TITLE'] }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="activeTab === 'fullSearch' ? 'active' : ''"
               @click="activeTab = 'fullSearch'">{{ translations['FULL_TEXT_SEARCH'] }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container-fluid chooserMain">
      <div class="row">
        <div class="col-12 chooserCategory" :class="activeTab === 'brandFilter' ? 'active' : ''"
             @click="activeTab = 'brandFilter'">
          <h3 class="text-center d-lg-none">
            <a>
              {{ translations['FILTER_BY_BRAND_TITLE'] }}
            </a>
          </h3>
          <form>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h2 class="introText">
                    <span v-html="translations['FILTER_DESCRIPTION']"></span>
                  </h2>
                </div>
              </div>
              <div class="row">
                <div class="dropdownContainer col-12 col-xl-3">
                  <select class="custom-select"
                          @change="onChange($event)" id="brand" name="brand">
                    <option value="" :selected="brandId === null" disabled>
                      {{ translations['FILTER_BY_BRAND'] }}
                    </option>
                    <option v-for="(brand) in brands" :value="brand.VALUE"
                            :selected="parseInt(brandId) === parseInt(brand.VALUE)">
                      {{ brand.KEY }}
                    </option>
                  </select>
                </div>
                <div class="dropdownContainer col-12 col-xl-3">
                  <select class="custom-select" @change="onChange($event)"
                          :disabled="isActive['model'] === false" id="model" name="model">
                    <option value="" selected disabled>{{ translations['FILTER_BY_MODEL'] }}
                    </option>
                    <option v-for="model in this.filteredModels" :value="model.VALUE"
                            :data-parent="model.VEHICLEBRAND_FK">
                      {{ model.KEY }}
                    </option>
                  </select>
                </div>
                <div class="dropdownContainer col-12 col-xl-3">
                  <select class="custom-select" @change="onChange($event)"
                          :disabled="isActive['piece'] === false" id="piece">
                    <option value="" selected disabled>{{ translations['FILTER_BY_PIECE'] }}
                    </option>
                    <optgroup v-for="group in categorizedPieces" :label="group.title">
                      <option v-for="piece in group.content" :value="piece.PK">
                        {{ piece.INSTRUMENTACTION }}
                      </option>
                    </optgroup>
                  </select>
                </div>
                <div class="col-12 col-xl-3 showDevicesBtn">
                  <button type="button" class="btn btn-dark btn-lg btn-block" id="search"
                          @click="startSearch()" :disabled="isActive['search'] === false">
                    {{ translations['SHOW_DEVICES'] }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12 chooserCategory" :class="activeTab === 'fullSearch' ? 'active' : ''"
             @click="activeTab = 'fullSearch'">
          <h3 class="text-center d-lg-none">
            <a>{{ translations['FULL_TEXT_SEARCH'] }}</a>
          </h3>
          <form>
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h2 class="introText">
                    <span v-html="translations['FILTER_DESCRIPTION']"></span>
                  </h2>
                </div>
              </div>
              <div class="row">
                <div class="dropdownContainer col-12 col-xl-9">
                  <span class="input-search-wrapper">
                    <input class="form-control" type="search" :placeholder="translations['FILTER_FREETEXT_PLACEHOLDER']"/>
                  </span>
                </div>
                <div class="col-12 col-xl-3 showDevicesBtn">
                  <button type="button" class="btn btn-dark btn-lg btn-block text-search" v-html="translations['FILTER_FREETEXT_BUTTON']"></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChooserWidget from "../chooserWidget"
export default {
  name: "indexPageChooser",
  mixins: [ChooserWidget],
  props: ['pieceSearch'],
  methods: {

  }
}
</script>