<template></template>

<script>
    import {mapState} from "vuex"

    export default {
        name: "chooserWidget",
        data() {
            return {
                activeTab: 'brandFilter',
                isActive: {
                    brand: true,
                    model: false,
                    piece: false,
                    search: false
                },
                brandId: null,
                modelId: null,
                pieceId: null,
                pieceDetails: null
            }
        },
        props: ['translations', 'brands', 'models', 'pieces', 'useSubmit', 'useService'],
        methods: {
            categorizePieces: function groupBy(array) {
                let result = {};
                result['repair'] = {'title': '', 'content': []};
                result['navirepair'] = {'title': '', 'content': []};
                result['controlunit'] = {'title': '', 'content': []};
                array.forEach(item => {
                    if (!result[item['CLASS']]) {
                        result[[item['CLASS']]] =
                            {
                                'title': item['INSTRUMENTACTIONTYPE'],
                                'content': []
                            }
                    } else if (result[item['CLASS']]['title'] === '') {
                        result[item['CLASS']]['title'] = item['INSTRUMENTACTIONTYPE'];
                    }
                    result[item['CLASS']]['content'].push(item);
                });
                for (let category in result) {
                    if (result[category]['content'] < 1) {
                        delete result[category];
                    } else {
                      let pieces = result[category]['content'];
                      pieces.sort(
                          function compare(a,b) {
                            if ( a.INSTRUMENTACTION < b.INSTRUMENTACTION ){
                              return -1;
                            }
                            if ( a.INSTRUMENTACTION > b.INSTRUMENTACTION ){
                              return 1;
                            }
                            return 0;
                          }
                      );
                    }
                }
                return result;
            },
            onChange(val, submit) {
                let changedSelector = null;
                let newValue = null;

                if (val === 0) {
                    changedSelector = 'brand';
                    newValue = this.brandId = null;
                } else if (typeof val === 'number') {
                    changedSelector = 'brand';
                    newValue = this.brandId = val.toString();
                } else {
                    changedSelector = val.currentTarget.id;
                    newValue = val.currentTarget.value;
                }

                if (newValue) {
                    let activateNextCategory = false;
                    let disableNextCategory = false;
                    let elementId = '';
                    this.search = false;
                    switch (changedSelector) {
                        case 'brand':
                            this.brandId = newValue;
                            this.modelId = '';
                            this.pieceId = '';
                            this.search = false;
                            break
                        case 'model':
                            this.modelId = newValue;
                            this.pieceId = '';
                            this.search = false;
                            break;
                        case 'piece':
                            this.pieceId = newValue;
                            this.search = true;
                            break;
                    }
                    Object.keys(this.isActive).forEach(key => {
                        let element = document.getElementById(key);
                        if (element) {
                          if (this.isActive[key] === true && changedSelector === key) {
                            elementId = element.value;
                            activateNextCategory = true;
                          } else if (activateNextCategory) {
                            if (this.isActive[key]) {
                              element.selectedIndex = 0;
                              disableNextCategory = true;
                            } else {
                              this.isActive[key] = true;
                            }
                            activateNextCategory = false;
                          } else if (disableNextCategory) {
                            element.selectedIndex = 0;
                            this.isActive[key] = false;
                          }
                      }

                    });
                }
                if (submit) {
                  if (this.useService) {
                    this.fetchPieceData();
                  } else {
                    if (this.useSubmit) {
                      $(submit).submit();
                    }
                  }
                }
            },
            startSearch() {
                let selectedPiece = this.$props.pieces.find(obj => {
                    return parseInt(obj.PK) === parseInt(this.pieceId)
                });
                window.location.href = this.getProductLink(
                    selectedPiece['CLASS'],
                    selectedPiece['NAVIGATION_FK'],
                    selectedPiece['VEHICLEBRAND_FK'],
                    selectedPiece['PAGETYPE'],
                    selectedPiece['PK'],
                    selectedPiece
                )
            },
            fetchPieceData() {
              const that = this;
              const host = window.location.protocol+"//"+window.location.hostname;
              fetch(host+"/api/v1/product/details/?id="+this.pieceId).then(async (response) => {
                that.pieceDetails = (await response.json())['data'][0];
                that.$store.commit('setOrderFormPieceDetails', that.pieceDetails);
              });
            }
        },
        computed: {
            filteredModels: function () {
                let that = this;
                return this.models.filter(function(model) {
                  return (parseInt(model.VEHICLEBRAND_FK) === parseInt(that.brandId))
                });
            },
            categorizedPieces: function () {
                let filteredPieces = this.pieces.filter(piece => parseInt(piece.VEHICLEMODEL_FK) === parseInt(this.modelId));
                if (filteredPieces.length) {
                    filteredPieces = this.categorizePieces(filteredPieces);
                }
                return filteredPieces;
            },
            ...mapState([
                'selectedBrand'
            ])
        },
        watch: {
            selectedBrand: function (brandName) {
              let brand = this.brands.filter(brand => String(brand.KEY) === String(brandName))
              if (brand.length) {
                this.onChange(Number(brand[0].VALUE));
              } else {
                this.onChange(0)
              }
            }
        }
    }
</script>